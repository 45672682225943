@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
.rsg-github {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  color: #000;
  background-color: #fff; }
  .rsg-github a,
  .rsg-github span {
    display: block;
    text-decoration: none; }
  .rsg-github a {
    color: gray; }
    .rsg-github a:hover {
      text-decoration: underline; }
  .rsg-github-is-loading {
    display: none; }
  .rsg-github-wrapper {
    box-shadow: rgba(93, 124, 154, 0.5) 0 1px 10px;
    display: inline-block;
    width: auto;
    max-width: 240px;
    padding: 24px;
    text-align: center;
    background: #fafbfc;
    border-radius: 3px; }
    @media screen and (min-width: 24em) {
      .rsg-github-wrapper {
        min-width: 240px; } }
  .rsg-github .rsg-inline-link {
    display: inline;
    cursor: pointer; }
  .rsg-github .rsg-btn-icon {
    background: none !important;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer; }
    .rsg-github .rsg-btn-icon:focus, .rsg-github .rsg-btn-icon:hover {
      opacity: 0.5; }
    .rsg-github .rsg-btn-icon:focus {
      outline: 0; }
    .rsg-github .rsg-btn-icon svg:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .rsg-github .rsg-btn-fab {
    position: fixed;
    background: #fff;
    border-radius: 50%; }
    .rsg-github .rsg-btn-fab--top-left {
      top: 16px;
      left: 16px; }
    .rsg-github .rsg-btn-fab--top-right {
      top: 16px;
      right: 16px; }
    .rsg-github .rsg-btn-fab--bottom-left {
      bottom: 16px;
      left: 16px; }
    .rsg-github .rsg-btn-fab--bottom-right {
      bottom: 16px;
      right: 16px; }
  .rsg-github .rsg-loading-icon {
    width: 80px;
    height: 80px; }
  .rsg-github .rsg-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto; }
  .rsg-github .rsg-name {
    margin-top: 8px;
    margin-bottom: 6.4px;
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word; }
  .rsg-github .rsg-info {
    margin-bottom: 2px;
    font-size: 14.54545px;
    font-weight: 300; }
    .rsg-github .rsg-info--italic {
      font-style: italic; }
    .rsg-github .rsg-info--secondary {
      font-size: 12.8px;
      font-weight: 400;
      text-transform: uppercase;
      color: #999999; }
  .rsg-github .rsg-counters {
    display: inline-block;
    margin-top: 16px; }
    .rsg-github .rsg-counters .rsg-item {
      display: inline-block;
      padding-left: 16px;
      padding-right: 16px; }
      .rsg-github .rsg-counters .rsg-item .rsg-icon {
        display: inline-block;
        vertical-align: top;
        margin-right: 10.66667px; }
        .rsg-github .rsg-counters .rsg-item .rsg-icon svg {
          fill: #999999;
          width: 20px;
          height: 36px; }
      .rsg-github .rsg-counters .rsg-item .rsg-description {
        display: inline-block;
        text-align: left; }
        .rsg-github .rsg-counters .rsg-item .rsg-description .rsg-count {
          display: block;
          font-size: 16px;
          font-weight: 700; }
        .rsg-github .rsg-counters .rsg-item .rsg-description .rsg-label {
          margin-top: 2px;
          font-size: 10.66667px;
          font-weight: 300;
          text-transform: uppercase; }
      .rsg-github .rsg-counters .rsg-item:not(:last-child) {
        border-right: 1px solid #bac8d6; }
  .rsg-github .rsg-status {
    display: inline-block;
    margin-bottom: 10.66667px; }
    .rsg-github .rsg-status .rsg-available {
      display: inline-block;
      padding: 8px 16px;
      text-transform: uppercase;
      font-size: 12.8px;
      font-weight: 400;
      color: #fff;
      border-radius: 4px;
      background-color: #cce5cc;
      color: #3c763d; }
  .rsg-github .rsg-btn-github {
    display: block;
    margin-top: 24px;
    padding: 8px 16px;
    color: #000;
    font-size: 13.33333px;
    font-weight: 700;
    border-radius: 4px;
    background-color: #eaeef2;
    border: 1px solid #cad5df;
    text-shadow: 0 1px 0 #fafbfc;
    white-space: nowrap;
    cursor: pointer; }
    .rsg-github .rsg-btn-github:active, .rsg-github .rsg-btn-github:focus, .rsg-github .rsg-btn-github:hover {
      text-decoration: none !important;
      background-color: #dae2e9;
      border-color: #bac8d6; }
    .rsg-github .rsg-btn-github .rsg-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      vertical-align: middle; }
    .rsg-github .rsg-btn-github .rsg-text {
      display: inline !important;
      display: initial !important;
      vertical-align: middle; }

.rsg-github-tooltip {
  position: fixed;
  z-index: 9999; }
  .rsg-github-tooltip-animation-enter {
    opacity: 0.01; }
  .rsg-github-tooltip-animation-leave {
    opacity: 1; }

.rsg-github-tooltip-animation-enter.rsg-github-tooltip-animation-enter-active {
  opacity: 1;
  transition: opacity 100ms linear; }

.rsg-github-tooltip-animation-leave.rsg-github-tooltip-animation-leave-active {
  opacity: 0.01;
  transition: opacity 100ms linear; }




